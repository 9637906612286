// Import Images
import background from '../../../assets/Images/image-skilsmisse.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Page5data = {
  pageNAme: 'Page5',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_5',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: background,
    bannerContent:
      '<h1>Få overblik over din pension</h1>'
      + '<p classType="intro">Savner du overblik over din pension - hvad indeholder ordningen egentlig, hvor stor er min opsparing, hvor meget indbetaler jeg og sparer jeg nok op? Her er tre indgange til at få overblik.</p>',
    desktopTools: null,
  },
  pageContent: [
    {
      componentName: 'InfoTagsPages',
      componentData: {
        menu: [
          {
            id: 1,
            header: 'Menu #1',
            subMenu: [
              {
                id: 2,
                header: 'sub menu #1.1',
              },
              {
                id: 3,
                header: 'sub menu #1.2',
              },
              {
                id: 4,
                header: 'sub menu #1.3',
              },
            ],
          },

          {
            id: 5,
            header: 'Menu #2',
            subMenu: [
              {
                id: 6,
                header: 'sub menu #2.1',
              },
              {
                id: 7,
                header: 'sub menu #2.2',
              },
            ],
          },
          {
            id: 8,
            header: 'Menu #3',
            subMenu: [],
          },
        ],
      },
      componentChildren: [
        {
          componentName: 'DivContainer',
          componentData: [],
          componentChildren: [
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<h2>menu #1 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
              },
            },
            // Collapsable FAQ
            {
              componentName: 'CollapseableFAQ',
              componentData: {
                header: 'FAQ / Pension og skilsmisse',
                faqs: [
                  {
                    id: '1',
                    header: 'Hvad sker der med mine pensioner?',
                    text:
                      'Reglerne ved separation og skilsmisse er, at du altid kan beholde "rimelige pensioner".<br /><br />Almindelige arbejdsmarkedspensioner er altid rimelige, eftersom du kun betaler det beløb, der fx er fastsat i din lønoverenskomst. Som hovedregel skal værdien af din pension i Sampension ikke deles. Men din ægtefælle skal måske have en kompensation.',
                  },
                  {
                    id: '2',
                    header: 'Kan jeg beholde mine pensioner?',
                    text: 'text 2',
                  },
                  {
                    id: '3',
                    header: 'Hvad sker der med ægtefællepensionen ?',
                    text: 'text 3',
                  },
                ],
              },
            },
          ],
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #2 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #3 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #4 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #5 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #6 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #7 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>menu #8 tekst</h2><p>Din alderspension er beregnet ud fra en prognose. Prognosen er ikke et bindende løfte, men et beregnet skøn på størrelsen af din alderspension, når du går på pension. Ved beregning af prognosen har vi forudsat, at der ikke sker ændringer i lovgivningen, den bonusmetode der anvendes på din pensionsordning, samt at der ikke sker ændringer i det tekniske grundlag, der anvendes for at beregne pensionernes størrelse.<br /><br />Herudover indgår en række forudsætninger i beregningen, hvoraf nogle er fælles for alle pensionsselskaber og andre er fastsat af Sampension. De oplyste forudsætninger er gældende fra 1. januar 2018, og kan ændres fra den første i en måned, hvis Sampension finder det nødvendigt.<br /><br />Ændringer i de nævnte forhold og forudsætninger vil betyde at prognosen ændres.</p>',
          },
        },
      ],
    },
  ],
};

// Exports
export default Page5data;
