// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../template/RenderPage';

// TempData
import Page5data from '../mocks/pages/PageData/page5';

// Class
const Page5 = () => <div>{renderPage(Page5data)}</div>;

export default Page5;
